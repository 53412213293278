import React from 'react';
import { Helmet } from "react-helmet";
import {useNavigate, Link} from "react-router-dom";
import {Header404} from "../components";
import Footer from "../components/Footer";

const Notfound = () => {
  const navigator = useNavigate();

  return (
    <div>
      <Header404 />

      <div className="notFoundSection">
        <Helmet>
          <title>페이지를 찾을 수 없습니다. | 셀러허브 이지셀 SELLERHUB</title>
        </Helmet>

        <div className="notFoundWrap">
          <h1>404 NOT FOUND <img src="/assets/images/circle-exclamation-solid.svg" style={{width: '40px'}} alt="!" /></h1>

          <p>요청하신 페이지를 찾을 수 없습니다.<br />
            입력하신 주소가 정확한지 다시 한번 확인해주세요.</p>

          <button className="backToPage" onClick={()=> navigator(-1)}>이전 페이지로 돌아가기</button>
          <Link to="/" className="backToPage">메인페이지로 가기</Link>
        </div>
      </div>

      <Footer />
    </div>

  )
}
export default Notfound;
import React from 'react';
import {useNavigate} from 'react-router-dom';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import backArrow from '../assets/images/arrow-left-long-solid.svg';

const Privacy = () => {
  const navigator = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="privacySection">
      <Helmet>
        <title>개인정보 처리방침 {t('pageTitle.common')}</title>
      </Helmet>

      <button className="backToPage" onClick={()=> navigator(-1)}><img src={backArrow} alt="Back" /></button>

      <h1>개인정보 처리방침</h1>

      <div className="privacyContents">
        <div className={'wrap_policy'}>
          <p>
            주식회사 셀러허브 이지셀(이하 “회사”)는 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』등 모든 관련 법규를 준수하며, 회사의 서비스를 이용하는 고객(이하”이용자”)의 개인정보가 보호받을 수 있도록 최선을 다하고 있습니다.
            회사는 개인정보처리방침의 공개를 통하여 이용자의 개인정보가 어떠한 목적과 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지를 알려 드립니다.
            본 개인정보처리방침은 관련 법령의 개정이나 회사 내부방침에 의해 변경될 수 있으므로 회원 가입 시나 셀러허브 이지셀 이용 시에 수시로 확인하여 주시기 바랍니다.
          </p>
          <div className={'area_cell'}>
            <p>본 개인정보처리방침의 목차는 아래와 같습니다. </p>
            <p>관계법령이 요구하는 개인정보 처리방침의 필수 사항과 셀러허브 이지셀 자체적으로 이용자 개인정보 보호에 있어 중요하게 판단하는 내용을 포함하였습니다.</p>
            <ol className={'cell_deep'}>
              <li>1. 개인정보처리방침의 의의</li>
              <li>2. 수집하는 개인정보의 항목 및 수집방법</li>
              <li>3. 개인정보의 수집 및 이용목적</li>
              <li>4. 개인정보의 공유 및 제공</li>
              <li>5. 개인정보의 처리위탁</li>
              <li>6. 개인정보의 보유 및 이용기간</li>
              <li>7. 개인정보의파기절차 및 방법</li>
              <li>8. 이용자 및 법정대리인의 권리와 구 행사방법</li>
              <li>9. 개인정보의 자동 수집 장치의 설치, 운영 및 그 거부에 관한 사항</li>
              <li>10. 개인정보의 기술적/ 관리적 보호 대책</li>
              <li>11. 영업 양수 등의 통지</li>
              <li>12. 개인정보보호책임자 및 담당자 안내</li>
              <li>13. 본 개인정보처리방침의 적용 범위</li>
            </ol>
          </div>
          <h2>1. 개인정보처리방침의 의의</h2>
          <div className={'area_cell'}>
            회사는 본 개인정보처리방침은 개인정보보호법을 기준으로 작성하되, 회사 내에서의 이용자 개인정보 처리 현황을 최대한 알기 쉽고 상세하게 설명하기 위해 노력하였습니다.<br />
            이는 쉬운 용어를 사용한 개인정보처리방침 작성 원칙인 ‘Plain Language Privacy Policy(쉬운 용어를 사용한 개인정보처리방침)’를 도입한 것입니다.<br />
            개인정보처리방침은 다음과 같은 중요한 의미를 가지고 있습니다.<br />
            회사가 어떤 정보를 수집하고, 수집한 정보를 어떻게 사용하며, 필요에 따라 누구와 이를 공유(‘위탁 또는 제공’)하며, 이용목적을 달성한 정보를 언제·어떻게 파기하는지 등 ‘개인정보의 한살이’와 관련한 정보를 투명하게 제공합니다.<br />
            정보주체로서 이용자는 자신의 개인정보에 대해 어떤 권리를 가지고 있으며, 이를 어떤 방법과 절차로 행사할 수 있는지를 알려드립니다. 또한, 법정대리인(부모 등)이 만14세 미만 아동의 개인정보 보호를 위해 어떤 권리를 행사할 수 있는지도 함께 안내합니다.<br />
            개인정보 침해사고가 발생하는 경우, 추가적인 피해를 예방하고 이미 발생한 피해를 복구하기 위해 누구에게 연락하여 어떤 도움을 받을 수 있는지 알려드립니다.<br />
            그 무엇보다도, 개인정보와 관련하여 회사와 이용자간의 권리 및 의무 관계를 규정하여 이용자의 ‘개인정보자기결정권’을 보장하는 수단이 됩니다.<br />
          </div>

          <h2>2. 수집하는 개인정보의 항목 및 수집방법</h2>
          <div className={'area_cell'}>이용자가 개인화 혹은 회원제 서비스를 이용하기 위해 회원가입을 할 경우, 회사는 서비스 이용을 위해 필요한 최소한의 개인정보를 수집합니다.</div>
          <div className={'area_cell'}>회원가입 시점에 회사가 이용자로부터 수집하는 개인정보는 아래와 같습니다.</div>
          <ul className={'list_depth_1'}>
            <li>
              수집하는 개인정보의 항목
              <ul className={'list_depth_2'}>
                <li>1. 회사는 회원가입 또는 서비스 이용 시 고객상담, 각종 서비스의 제공을 위하여 다음과 같은 개인정보를 수집 이용하고 있습니다.
                  <ul className={'list_depth_3'}>
                    <li>가. 필수정보 <br/>
                      사용자아이디, 비밀번호, 상호(법인명), 성명(대표자명), 사업자등록번호, 업종/업태, 대표 전화번호, 사업장주소, 반송지주소, 고객센터 번호, 거래은행명, 예금주, 계좌번호, 정산담당자, 정산담당자 이메일, 정산담당자 연락처, 정산담당자휴대전화, 쇼핑몰명, 상점 아이디, 상점 등급, 해당 쇼핑몰의 등급, 대표도메인, 대표 팩스번호, 대표 이메일</li>
                    <li>나. 선택정보 <br/>
                      쇼핑몰 주소, 통신판매신고 번호, 회사소개, 회사약도, 서비스 문의안내 내용, 고객센터 상담/주문 전화, 고객센터 상담/주문 이메일, 고객센터 팩스 번호, 고객센터 SMS 수신번호, 고객센터 운영시간, 개인정보보호 책임자명, 개인정보보호 책임자 지위, 개인정보보호 책임자 부서, 개인정보보호 책임자 연락처, 개인정보보호 책임자 이메일</li>
                  </ul>
                  <p>※ 선택 정보값이 존재할 경우 회원가입 시 자동으로 받아오며 존재하지 않는 경우 기타 기능 사용을 위해 추가 입력을 요청할 수 있습니다.</p>
                </li>
                <li>2. 서비스 이용과정이나 사업처리 과정에서 다음과 같은 정보들이 자동으로 생성되어 수집 될 수있습니다. ? IP Adress, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록</li>
              </ul>
            </li>
            <li>
              개인정보 수집방법
              <p>회사는 다음과 같은 방법으로 개인정보를 수집합니다.</p>
              <ul className={'list_depth_3'}>
                <li>가. 셀러허브 이지셀 회원가입, 서비스 이용, 서면양식, 전화, 상담게시판, 이메일, 마케팅 및 광고, 배송요청</li>
                <li>나. 입점사로부터의 제공</li>
              </ul>
            </li>
            <li>회사는 기본적 인권침해의 우려가 있는 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등)는 요구하지 않습니다.</li>
          </ul>

          <h2>3. 개인정보의 수집 및 이용목적</h2>
          <div className={'area_cell'}>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</div>
          <ul className={'list_depth_1'}>
            <li>1. 서비스 제공에 관한 계약 이행 및 유료 서비스 제공에 따른 요금 정산, 콘텐츠 제공, 유료 서비스 이용에 대한 과금, 구매 및 요금 결제, 본인 인증, 청구서 등 발송, 요금 추심</li>
            <li>2. 입점사 서비스 이용에 따른 본인확인, 개인식별, 불량회원의 부정 이용 방지와 비인가 사용방지, 중복가입 확인, 가입의사 확인, 분쟁 조정을 위한 기록보전, 불만처리 등 민원처리, 고지사항 전달</li>
            <li>3. 신규 서비스 개발 및 마케팅 및 광고에 활용 신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 접속 빈도 파악, 회원의 서비스 이용에 대한 통계, 이벤트 정보 및 참여기회 제공, 광고성 정보 제공</li>
          </ul>

          <h2>4. 개인정보의 공유 및 제공</h2>
          <ul className={'list_depth_1'}>
            <li>
              회사는 이용자의 개인정보를 (3. 개인정보의 수집 및 이용목적)에서 고지한 범위 내에서 이용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 타인 또는 타 기관에 제공 또는 공유하지 않습니다. 다만, 다음의 경우에는 예외로 합니다.
              <ul className={'list_depth_2'}>
                <li>1. 이용자가 사전에 동의 한 경우</li>
                <li>2. 법령의 규정에 의거하거나, 수사상 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</li>
                <li>3. 서비스 제공에 따른 요금 정산을 위해 필요한 경우</li>
                <li>4. 통계작성, 학술연구 또는 시장조사를 위하여 개인을 식별할 수 없는 형태로 제공하는 경우</li>
              </ul>
            </li>
            <li>회사는 이용자에 대하여 보다 질 높은 서비스 제공 등을 위해 사전에 동의를 획득한 경우에 한하여 이용자의 개인정보를 제공하고 있습니다.</li>
          </ul>

          <h2>5. 개인정보의 처리위탁</h2>
          <div className={'area_cell'}>회사는 서비스 이행을 위해 이용자의 개인정보를 위탁 처리할 경우 위탁하는 내용 및 수탁자를 고지하고, 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정해 관련 법규를 준수하고 이를 감독합니다. <br />회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 다음과 같습니다.</div>
          <table className={'tbl_policy'}>
            <caption></caption>
            <colgroup>
              <col />
              <col />
              <col />
            </colgroup>
            <thead>
            <tr><th>수탁업체</th><th>위탁업무 내용</th><th>개인정보 보유 및 이용기간</th></tr>
            </thead>
            <tbody>
            <tr><td>NICE신용평가정보주식회사</td><td>신용카드 결제 처리</td><td>회원탈퇴 혹은 입점계약 종료시까지</td></tr>
            <tr><td>알리고㈜</td><td>SMS, LMS 전송</td><td>회원탈퇴 혹은 입점계약 종료시까지</td></tr>
            <tr><td>㈜엔앰와이즈</td><td>카카오톡 알림톡 발송 업무</td><td>회원탈퇴 혹은 입점계약 종료시까지</td></tr>
            <tr><td>씨제이대한통운㈜</td><td>상품배송 및 배송위치, 도착정보 등의 서비스 제공</td><td>회원탈퇴 혹은 입점계약 종료시까지</td></tr>
            </tbody>
          </table>

          <h2>6. 개인정보의 보유 및 이용기간</h2>
          <ul className={'list_depth_1'}>
            <li>이용자의 개인정보는 이용자가 회원탈퇴를 요청하거나 제공한 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 또는 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다.<br />
              단, 다음의 정보에 대해서는 보존근거에서 명시한 근거에 따라 보존기간 동안 보존합니다.
              <ul className={'list_depth_2'}>
                <li>1. 보전 항목 : 상호(법인명), 성명(대표자명)</li>
              </ul>
            </li>
            <li>상법, 전자상거래 등에서의 소비자보호에 관한 법를 등 관계 법령의 규정에 의해 보존할 필요성이 있는 경우, 회사는 관계법령에서 정한 일정한 기간 이상 이용자의 개인정보를 보관할 수 있습니다.<br />
              이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며, 보존 근거에서 명시한 근거에 따라 보존기간 동안 보존합니다.
              <ul className={'list_depth_2'}>
                <li>1. 계약 또는 청약철회 등에 관한 기록
                  <ul className={'list_depth_2'}>
                    <li>가. 보존근거: 전자상거래 등에서의 소비자보호에 관한 법률</li>
                    <li>나. 보존기간 : 5년</li>
                  </ul>
                </li>
                <li>2. 대금결제 및 재화 등의 공급에 관한 기록
                  <ul className={'list_depth_2'}>
                    <li>가. 보존근거: 전자상거래 등에서의 소비자보호에 관한 법률</li>
                    <li>나. 보존기간 : 5년</li>
                  </ul>
                </li>
                <li>3. 전자금융 거래에 관한 기록
                  <ul className={'list_depth_2'}>
                    <li>가. 보존근거: 전자금융거래법</li>
                    <li>나. 보존기간 : 5년</li>
                  </ul>
                </li>
                <li>4. 소비자의 불만 또는 분쟁처리에 관한 기록
                  <ul className={'list_depth_2'}>
                    <li>가. 보존근거: 전자상거래 등에서의 소비자보호에 관한 법률</li>
                    <li>나. 보존기간 : 3년</li>
                  </ul>
                </li>
                <li>5. 표시광고에 관한 기록
                  <ul className={'list_depth_2'}>
                    <li>가. 보존근거: 전자상거래 등에서의 소비자보호에 관한 법률</li>
                    <li>나. 보존기간 : 6개월</li>
                  </ul>
                </li>
                <li>6. 웹사이트 방문 기록
                  <ul className={'list_depth_2'}>
                    <li>가. 보존근거: 통신비밀보호법</li>
                    <li>나. 보존기간 : 3개월</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>

          <h2>7. 개인정보의파기절차 및 방법</h2>
          <div className={'area_cell'}>
            <b className={'font-size:14px'}>회사는 원칙적으로 이용자의 개인정보를 회원 탈퇴 시 지체없이 파기하고 있습니다. </b><br/>
            단, 이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우, 또는 법령에서 일정 기간 정보보관 의무를 부과하는 경우에는 [6. 개인정보의 보유 및 이용기간]에 표기된 기간 만큼 개인정보를 안전하게 보관합니다.
          </div>

          <h2>8. 이용자 및 법정대리인의 권리와 구 행사방법</h2>
          <ul className={'list_depth_1'}>
            <li>이용자는 언제든지 이용자 본인의 개인정보를 조회하거나 정정할 수 있으며, 가입해지(회원탈퇴) 또는 개인정보의 삭제를 요청할 수 있습니다. 단, 서비스 제공을 위해 반드시 필요한 개인정보를 삭제하는 경우 관련 서비스를 제공받지 못할 수 있습니다.</li>
            <li>이용자의 개인정보를 조회, 정정하기 위하여는 개인정보변경을, 가입해지(동의철회)를 위하여는 탈퇴를 신청하여 본인 확인 절차 후 직접 조회, 정정 또는 가입해지(동의철회)할 수 있습니다. 개인정보보호책임자 및 담당자에게 판매자 지원 게시판, 서면, 전화 또는 이메일로 연락하시면 본인 확인 절차 후 지체 없이 필요한 조치를 하겠습니다.</li>
            <li>개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.</li>
            <li>회사는 이용자의 요청에 의해 해지 또는 삭제된 개인정보를 (6. 개인정보의 보유 및 이용기간)에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.</li>
          </ul>

          <h2>9. 개인정보의 자동 수집 장치의 설치, 운영 및 그 거부에 관한 사항</h2>
          <ul className={'list_depth_1'}>
            <li>회사는 이용자에게 개인화되고 맞춤화된 서비스를 제공하기 위해 이용자의 정보를 저장하고 수시로 찾아내는 쿠키(Cookie)를 사용합니다. 쿠키는 웹사이트가 이용자의 웹브라우저(인터넷익스플러러, 파이어폭스, 크롬 등)로 전송하는 소량의 정보입니다.</li>
            <li>이용자는 쿠키에 대한 선택권을 가지고 있습니다. 이용자는 웹브라우저에서[도구]&lt;[인터넷옵션]&lt;[개인정보]&lt;[설정]을 선택함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수 있습니다. 단, 모든 쿠키의 저장을 거부하는 경우, 쿠키를 통해 회사에서 제공하는 서비스를 이용할 수 없습니다.</li>
          </ul>

          <h2>10. 개인정보의 기술적/ 관리적 보호 대책</h2>
          <div className={'area_cell'}>회사는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위해 기술적, 관리적 대책을 마련하고 있습니다.</div>
          <ul className={'list_depth_2'}>
            <li>1. 기술적 대책
              <ul className={'list_depth_3'}>
                <li>가. 이용자의 비밀번호는 암호화되어 있어 이용자 본인 외에는 알 수 없습니다.</li>
                <li>나. 회사는 암호화 통신을 통하여 네트워크 상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.</li>
                <li>다. 회사는 해킹이나 컴퓨터바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.</li>
                <li>라. 회사는 개인정보의 훼손에 대비하여 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다.</li>
                <li>마. 회사는 시스템에 대한 접근통제, 권한 관리, 취약점 점검 등의 조치를 통해 보안성이 강화될 수 있도록 지속적으로 노력하고 있습니다.</li>
              </ul>
            </li>
            <li>2. 관리적 대책
              <ul className={'list_depth_3'}>
                <li>가. 회사는 이용자의 개인정보에 대한 접근권한을 최소한의 인원으로 제한하고 있습니다.</li>
                <li>나. 회사는 개인정보처리자를 대상으로 개인정보 보호 의무 등에 관해 정기적인 교육을 실시하고 있습니다.</li>
                <li>다. 회사는 개인정보보호 업무를 전담하는 부서에서 개인정보처리방침 및 내부 규정 준수여부를 확인하여 문제가 발견될 경우, 즉시 바로 잡을 수 있도록 노력하고 있습니다.</li>
                <li>라. 이용자 본인의 부주의나 회사가 관리하지 않는 영역에서의 사고 등 회사의 귀책에 기인하지 않은 손해에 대해서는 회사는 책임을 지지 않습니다.</li>
              </ul>
            </li>
          </ul>

          <h2>11. 영업 양수 등의 통지</h2>
          <div className={'area_cell'}>회사는 영업의 전부 또는 일부를 양도하거나 합병, 상속 등으로 그 권리, 의무를 이전하는 경우 이용자에게 관련 내용으로 다음의 항목을 통지합니다.</div>
          <ul className={'list_depth_2'}>
            <li>1. 영업의 전부 또는 일부의 양도, 합병 또는 상속의 사실</li>
            <li>2. 권리, 의무를 승계한 자의 성명, 주소, 연락처</li>
          </ul>

          <h2>12. 개인정보보호책임자 및 담당자 안내</h2>
          <ul className={'list_depth_1'}>
            <li>회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 다음과 같이 개인정보보호책임자를 두고 있습니다.
              <table className={'tbl_policy short'}>
                <caption></caption>
                <colgroup>
                  <col />
                </colgroup>
                <thead>
                <tr><th>개인정보보호책임자 개인정보보호담당자</th></tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    이름 : 김지예 <br />
                    전화번호 1522-9843 <br />
                    이메일 cs@sellerhub.co.kr <br />
                    소속 및 직위 : 팀장 / 고객서비스팀 <br />
                  </td>
                </tr>
                </tbody>
              </table>
              <br />
            </li>
            <li>기타 개인정보 침해에 대한 신고나 상담이 필요하신 경우 다음 기관에 문의하시기 바랍니다.
              <ul className={'list_depth_2'}>
                <li>가. 개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)</li>
                <li>나. 대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)</li>
                <li>다. 경찰청 사이버안전국 (police.go.kr / 국번없이 182)</li>
                <li>라. 개인정보 분쟁조정위원회(kopico.go.kr/ 1833-6972)</li>
              </ul>
            </li>
          </ul>

          <h2>13. 본 개인정보처리방침의 적용 범위</h2>
          <div className={'area_cell'}>본 개인정보처리방침은 회사의 브랜드 중 하나인 ‘셀러허브 이지셀(www.sellerhub.co.kr)’ 및 관련 제반 서비스(모바일 웹/앱 포함)에 적용되며, 다른 브랜드로 제공되는 서비스에 대해서는 별개의 개인정보처리방침이 적용될 수 있습니다. <br />
            셀러허브 이지셀에 링크되어 있는 다른 회사의 웹사이트에서 개인정보를 수집하는 경우, 이용자 동의 하에 개인정보가 제공된 이후에는 본 개인정보처리방침이 적용되지 않습니다.</div>

          <h2>14. 개정 전 고지 의무</h2>
          <div className={'area_cell'}>
            본 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 경우 개정 최소 7일 전에 ‘공지사항’을 통해 사전 공지를 할 것입니다.<br/>
            다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자 권리의 중대한 변경이 발생할 때에는 최소 30일 전에 공지하며, 필요 시 이용자 동의를 다시 받을 수도 있습니다.
          </div>
          <div className={'date-info'}>
            시행일자 : 2019년 01월 01일<br/>
            변경일자 : 2020년 12월 01일
          </div>
        </div>
      </div>

    </div>
  )
}
export default Privacy;